.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  max-width: 740px;
}

.disabled {
  pointer-events: none;
  color: #808178 !important;
}

h1 {
  font-size: 30px;
  font-family: 'Figtree', sans-serif;
  color: #1b1c1d;
  font-weight: 600;
  margin-bottom: 12px;
}

p {
  font-size: 18px;
  color: #1b1c1d;
  margin-bottom: 32px;
}

.reasons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.reason-item {
  width: 360px;
  height: 48px;
  background-color: #fbfbf8;
  border: 1px solid #ece9dd;
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
}

.reason-item.active {
  border-color: #1b1c1d;
}

.reason-item.active.disabled {
  pointer-events: none;
  border-color: #808178 !important;
}

.reason-item input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border: 1px solid #1b1c1d;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  background-color: #fbfbf8;
  position: relative;
}

.reason-item input[type='checkbox']:disabled {
  border: 1px solid #808178;
}

.reason-item input[type='checkbox']::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.reason-item input[type='checkbox']:checked::before {
  background-color: #1b1c1d;
}

.reason-item input[type='checkbox']:checked:disabled:before {
  background-color: #808178 !important;
}

.reason-item input[type='checkbox']::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 4px;
  height: 9px;
  border: solid #fbfbf8;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.reason-item input[type='checkbox']:checked::after {
  opacity: 1;
}

.reason-item input[type='checkbox']:focus {
  outline: none;
}

.reason-item label {
  font-size: 18px;
  color: #1b1c1d;
}

.additional-feedback {
  width: 100%;
  margin-top: 48px;
}

.additional-feedback textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #1b1c1d;
  background-color: #fbfbf8;
  margin-top: 20px;
  resize: none;
  color: #1b1c1d;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.additional-feedback textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow for focus indication */
}

.additional-feedback textarea:disabled {
  border-color: #808178 !important;
  color: #b8b5a5 !important;
}

.additional-feedback label {
  color: #1b1c1d;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
}

.submit-btn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  width: 160px;
  padding: 10px 20px;
  background-color: #1b1c1d;
  color: #fbfbf8;
  border-radius: 100px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.submit-btn:disabled {
  pointer-events: none;
  background-color: #b8b5a5 !important;
  color: #ece9dd !important;
}

.submit-btn:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .reasons {
    grid-template-columns: 1fr;
  }
}

.sad-icon {
  width: 39px;
  height: 39px;
  min-width: 39px;
  min-height: 39px;
  margin-bottom: 12px;
}

.submitted-icon {
  position: absolute;
  right: 20px;
  width: 19.638px;
  height: 13.648px;
}
